import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "company_payroll_timelines",
  initialState: {
    list: [],
    loading: false,
    deletion_loading: false,
    saving_loading: false,
    saved: false,
  },

  reducers: {
    companyPayrollTimelinesRequested: (state, action) => {
      state.list = [];
      state.loading = true;
    },

    companyPayrollTimelinesReceived: (state, action) => {
      state.list = action.payload.company_payroll_timelines;
      state.loading = false;
    },

    companyPayrollTimelinesRequestFailed: (state, action) => {
      state.loading = false;
    },

    companyPayrollTimelineCreationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    companyPayrollTimelineCreationReceived: (state, action) => {
      const { company_payroll_timelines } = action.payload;

      state.list = company_payroll_timelines;
      state.saving_loading = false;
      state.saved = true;
    },

    companyPayrollTimelineCreationFailed: (state, action) => {
      state.saving_loading = false;
      state.saved = false;
    },

    companyPayrollTimelineUpdationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    companyPayrollTimelineUpdationReceived: (state, action) => {
      const { company_payroll_timelines } = action.payload;

      // Iterate over the received timelines
      company_payroll_timelines.forEach((updatedTimeline) => {
        const index = state.list.findIndex(
          (stat) => stat.id === updatedTimeline.id
        );

        // Replace the existing record if it exists
        if (index !== -1) {
          state.list[index] = updatedTimeline;
        }
      });

      // Update other state properties
      state.saving_loading = false;
      state.saved = true;
    },

    companyPayrollTimelineUpdationFailed: (state, action) => {
      state.saving_loading = false;
      state.saved = false;
    },

    companyPayrollTimelineDeletionRequested: (state, action) => {
      state.deletion_loading = true;
    },

    companyPayrollTimelineDeletionReceived: (state, action) => {
      const { id } = action.payload;
      const index = state.list.findIndex((dep) => dep.id === id);
      state.list.splice(index, 1);
      state.deletion_loading = false;
    },

    companyPayrollTimelineDeletionFailed: (state, action) => {
      state.deletion_loading = false;
    },

    resetActionStates: (state, action) => {
      state.loading = false;
      state.saving_loading = false;
      state.saved = false;
      state.deletion_loading = false;
    },
  },
});

export default slice.reducer;

const {
  companyPayrollTimelinesRequested,
  companyPayrollTimelinesReceived,
  companyPayrollTimelinesRequestFailed,
  companyPayrollTimelineCreationRequested,
  companyPayrollTimelineCreationReceived,
  companyPayrollTimelineCreationFailed,
  companyPayrollTimelineUpdationRequested,
  companyPayrollTimelineUpdationReceived,
  companyPayrollTimelineUpdationFailed,
  companyPayrollTimelineDeletionRequested,
  companyPayrollTimelineDeletionReceived,
  companyPayrollTimelineDeletionFailed,
  resetActionStates,
} = slice.actions;

const url = "v1/hrms/company_payroll_timelines";

export const getCompanyPayrollTimelines = (payload) => (dispatch) => {
  let endpoint = url;
  if (payload?.financialYear) {
    endpoint = `${endpoint}?financial_year=${payload.financialYear}`;
  }
  return dispatch(
    apiCallBegan({
      url: endpoint,
      onStart: companyPayrollTimelinesRequested.type,
      onSuccess: companyPayrollTimelinesReceived.type,
      onError: companyPayrollTimelinesRequestFailed.type,
    })
  );
};

export const createCompanyPayrollTimelines = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: payload.payload,
      type: "creation",
      onStart: companyPayrollTimelineCreationRequested.type,
      onSuccess: companyPayrollTimelineCreationReceived.type,
      onError: companyPayrollTimelineCreationFailed.type,
      showAlert: true,
      successMessage: "Payroll timelines created successfully!",
    })
  );
};

export const updateCompanyPayrollTimelines = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: payload.payload,
      type: "updation",
      onStart: companyPayrollTimelineUpdationRequested.type,
      onSuccess: companyPayrollTimelineUpdationReceived.type,
      onError: companyPayrollTimelineUpdationFailed.type,
      showAlert: true,
      successMessage: "Payroll timeline updated successfully!",
    })
  );
};

export const deleteCompanyPayrollTimeline = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: companyPayrollTimelineDeletionRequested.type,
      onSuccess: companyPayrollTimelineDeletionReceived.type,
      onError: companyPayrollTimelineDeletionFailed.type,
      showAlert: true,
      successMessage: "Payroll timeline deleted successfully!",
    })
  );
};

export const resetActions = () => (dispatch) => {
  return dispatch(resetActionStates());
};
