import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "company_professional_taxes",
  initialState: {
    list: [],
    loading: false,
    deletion_loading: false,
    saving_loading: false,
    saved: false,
  },

  reducers: {
    companyProfessionalTaxesRequested: (state, action) => {
      state.list = [];
      state.loading = true;
    },

    companyProfessionalTaxesReceived: (state, action) => {
      state.list = action.payload.company_professional_taxes;
      state.loading = false;
    },

    companyProfessionalTaxesRequestFailed: (state, action) => {
      state.loading = false;
    },

    companyProfessionalTaxCreationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    companyProfessionalTaxCreationReceived: (state, action) => {
      const { company_professional_tax } = action.payload;

      if (company_professional_tax.primary) {
        state.list.forEach((account) => (account.primary = false));
      }

      state.list.push(company_professional_tax);

      state.saving_loading = false;
      state.saved = true;
    },

    companyProfessionalTaxCreationFailed: (state, action) => {
      state.saving_loading = false;
      state.saved = false;
    },

    companyProfessionalTaxUpdationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    companyProfessionalTaxUpdationReceived: (state, action) => {
      const { company_professional_tax } = action.payload;

      if (company_professional_tax.primary) {
        state.list.forEach((account) => (account.primary = false));
      }

      const index = state.list.findIndex(
        (stat) => stat.id === company_professional_tax.id
      );
      if (index !== -1) {
        state.list[index] = company_professional_tax;
      }

      state.saving_loading = false;
      state.saved = true;
    },

    companyProfessionalTaxUpdationFailed: (state, action) => {
      state.saving_loading = false;
    },

    companyProfessionalTaxDeletionRequested: (state, action) => {
      state.deletion_loading = true;
    },

    companyProfessionalTaxDeletionReceived: (state, action) => {
      const { id } = action.payload;
      const index = state.list.findIndex((dep) => dep.id === id);
      state.list.splice(index, 1);
      state.deletion_loading = false;
    },

    companyProfessionalTaxDeletionFailed: (state, action) => {
      state.deletion_loading = false;
    },

    resetActionStates: (state, action) => {
      state.loading = false;
      state.saving_loading = false;
      state.saved = false;
      state.deletion_loading = false;
    },
  },
});

export default slice.reducer;

const {
  companyProfessionalTaxesRequested,
  companyProfessionalTaxesReceived,
  companyProfessionalTaxesRequestFailed,
  companyProfessionalTaxCreationRequested,
  companyProfessionalTaxCreationReceived,
  companyProfessionalTaxCreationFailed,
  companyProfessionalTaxUpdationRequested,
  companyProfessionalTaxUpdationReceived,
  companyProfessionalTaxUpdationFailed,
  companyProfessionalTaxDeletionRequested,
  companyProfessionalTaxDeletionReceived,
  companyProfessionalTaxDeletionFailed,
  resetActionStates,
} = slice.actions;

const url = "v1/hrms/company_professional_taxes";

export const getCompanyProfessionalTaxes = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url,
      onStart: companyProfessionalTaxesRequested.type,
      onSuccess: companyProfessionalTaxesReceived.type,
      onError: companyProfessionalTaxesRequestFailed.type,
    })
  );
};

export const createCompanyProfessionalTax = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: { company_professional_tax: payload.company_professional_tax },
      type: "creation",
      onStart: companyProfessionalTaxCreationRequested.type,
      onSuccess: companyProfessionalTaxCreationReceived.type,
      onError: companyProfessionalTaxCreationFailed.type,
      showAlert: true,
      successMessage: "State profession tax added successfully!",
    })
  );
};

export const updateCompanyProfessionalTax = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: { company_professional_tax: payload.company_professional_tax },
      type: "updation",
      onStart: companyProfessionalTaxUpdationRequested.type,
      onSuccess: companyProfessionalTaxUpdationReceived.type,
      onError: companyProfessionalTaxUpdationFailed.type,
      showAlert: true,
      successMessage: "State profession tax updated successfully!",
    })
  );
};

export const deleteCompanyProfessionalTax = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: companyProfessionalTaxDeletionRequested.type,
      onSuccess: companyProfessionalTaxDeletionReceived.type,
      onError: companyProfessionalTaxDeletionFailed.type,
      showAlert: true,
      successMessage: "State profession tax deleted successfully!",
    })
  );
};

export const resetActions = () => (dispatch) => {
  return dispatch(resetActionStates());
};
